//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.dropdown-toggle::after {
	content: none !important;
}

.dropdown-toggle {
	background: transparent;
	color: #323232 !important;
	padding: 2px;
	border-color: transparent !important;
	border-radius: 2px;
}